<template>
  <div>
    <div class="card">
      <div class="card-body">
        <form ref="form" @submit.stop.prevent="handleSubmit">


          <div class="row">

            <div class="col-md-12">
              <div>
                <h4>Change Profile</h4>
                <div class="preview text-center">

                  <img v-if="image_preview" class="preview-img" :src="image_preview" alt="Preview Image"
                       width="200" height="200"/>

                  <img v-else-if="exist_image" class="preview-img" :src="userPhoto(exist_image)" alt="Preview Image"
                       width="200" height="200"/>

                  <img v-else class="preview-img"
                       :src="image_preview ? image_preview : `http://simpleicon.com/wp-content/uploads/account.png`"
                       alt="Preview Image" width="200" height="200"/>
                  <div class="browse-button">
                    <i class="fa fa-pencil-alt"></i>
                    <input class="browse-input" ref="profile_image" @change="onFileChange" type="file"
                           name="UploadedFile" id="UploadedFile1" accept=".png,.jpg,.jpeg"/>
                  </div>
                  <span class="Error"></span>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <b-form-group
                  label-for="name-input"
                  invalid-feedback="Pharmacy First Name required"
                  :state="first_name_state">
                <label for="">Pharmacy First Name <span class="text-danger">*</span></label>
                <b-form-input
                    id="name-input"
                    v-model="form.first_name"
                    :state="first_name_state"
                    required/>
              </b-form-group>
            </div>

            <div class="col-md-6">
              <b-form-group
                  label-for="name-input"
                  invalid-feedback="Pharmacy Last Name required"
                  :state="last_name_state">
                <label for="">Pharmacy Last Name <span class="text-danger">*</span></label>
                <b-form-input
                    id="name-input"
                    v-model="form.last_name"
                    :state="last_name_state"
                    required/>
              </b-form-group>
            </div>

            <div class="col-md-6">
              <b-form-group
                  label-for="name-input"
                  invalid-feedback="Telephone required"
                  :state="last_name_state">
                <label for="">Telephone <span class="text-danger"> * </span></label>
                <b-form-input
                    id="name-input"
                    v-model="form.phone_number"
                    :state="last_name_state"
                    required/>
              </b-form-group>
            </div>

            <div class="col-md-6">
              <b-form-group
                  label="Secondary Telephone "
                  label-for="name-input">
                <b-form-input
                    id="name-input"
                    v-model="form.pharmacy_details.secondary_phone_no"
                />
              </b-form-group>
            </div>

            <div class="col-md-6">
              <b-form-group
                  label-for="name-input">
                <label for="">Pharmacy owner's Name <span class="text-danger">*</span></label>
                <b-form-input
                    id="name-input"
                    v-model="form.pharmacy_details.organization_name"
                    required/>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group
                  label-for="name-input"
                  invalid-feedback="Pharmacy owner's Name required"
                  :state="last_name_state">
                <label for="">Pharmacist name </label>
                <b-form-input
                    id="name-input"
                    v-model="form.pharmacy_details.pharmacist_name"

                    />
              </b-form-group>
            </div>

            <div class="col-md-6">
              <b-form-group
                  label-for="name-input">
                <label for="">Drug license <span class="text-danger">*</span></label>
                <b-form-input
                    id="name-input"
                    v-model="form.pharmacy_details.drag_license"
                    required/>
              </b-form-group>
            </div>

            <div class="col-md-6">
              <b-form-group
                  label="TIN No"
                  label-for="name-input">
                <b-form-input
                    id="name-input"
                    v-model="form.pharmacy_details.tin_no"
                />
              </b-form-group>
            </div>

            <div class="col-md-6">
              <b-form-group
                  label-for="name-input">
                <label for="">NID card No. <span class="text-danger">*</span></label>
                <b-form-input
                    id="name-input"
                    v-model="form.identity_card_no"
                    required/>
              </b-form-group>
              <div class="row">
                <div class="col-md-12">
                  <div class="mb-3">
                    <label>Select District <span class="text-danger">*</span></label>

                    <v-select
                        :options="districts"
                        :reduce="districts => districts.id"
                        v-model="form.region_id"
                        @input="getThana"
                        label="name"
                        class=""
                        name="name">
                    </v-select>

                    <p class="text-danger"> {{ district_required }} </p>
                  </div>
                </div>

                <div class="col-md-12" v-if="form.region_id">
                  <div class="mb-3">
                    <label>Select Thana <span class="text-danger">*</span></label>
                    <v-select
                        :options="thana"
                        :reduce="thana => thana.id"
                        v-model="form.town_id"
                        label="name">
                    </v-select>
                    <p class="text-danger">{{ thana_required }}</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <h4 class="text-center">NID image</h4>
              <div class="preview text-center">

                <img v-if="image_preview_nid" class="preview-img-nid" :src="image_preview_nid" alt="Preview Image"
                     width="200" height="200"/>

                <img v-else-if="exist_image_nid" class="preview-img-nid" :src="userPhoto(exist_image_nid)"
                     alt="Preview Image"
                     width="200" height="200"/>

                <img v-else class="preview-img-nid"
                     :src="image_preview_nid ? image_preview_nid : `http://simpleicon.com/wp-content/uploads/account.png`"
                     alt="Preview Image" width="200" height="200"/>
                <div class="browse-button-nid">
                  <i class="fa fa-pencil-alt"></i>
                  <input class="browse-input-nid" ref="identity_card" @change="onFileChangeNid" type="file"
                         name="UploadedFile" id="UploadedFile2" accept=".png,.jpg,.jpeg"/>
                </div>
                <span class="Error"></span>
              </div>
            </div>


            <div class="col-md-12">
              <div class="mb-3">
                <label for="">Location <span class="text-danger">*</span></label>
                <b-form-textarea
                    id="textarea"
                    v-model="form.location"
                    rows="3"
                   required></b-form-textarea>
              </div>
            </div>

          </div>

          <b-button type="submit" variant="primary">Update</b-button>

        </form>
      </div>
    </div>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import {AgentEditMixin} from "../../../mixins/AgentEditMixin";

export default {
  name: "EditAgent",
  mixins: [AgentEditMixin],
}
</script>

<style scoped>
.preview {
  padding: 10px;
  position: relative;
}

.preview i {
  color: white;
  font-size: 35px;
  transform: translate(50px, 130px);
}

.preview-img {
  border-radius: 100%;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.7);
}

.preview-img-nid {
  /*border-radius: 100%;*/
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.7);
}

.browse-button {

  width: 200px;
  height: 200px;
  border-radius: 100%;
  position: absolute; /* Tweak the position property if the element seems to be unfit */
  top: 10px;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(180deg, transparent, black);
  opacity: 0;
  transition: 0.3s ease;
}

.browse-button-nid {
  width: 200px;
  height: 200px;
  /*border-radius: 100%;*/
  position: absolute; /* Tweak the position property if the element seems to be unfit */
  top: 10px;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(180deg, transparent, black);
  opacity: 0;
  transition: 0.3s ease;
}

.browse-button:hover {
  opacity: 1;
}

.browse-button-nid:hover {
  opacity: 1;
}

.browse-input {
  width: 200px;
  height: 200px;
  border-radius: 100%;
  transform: translate(-1px, -26px);
  opacity: 0;
}

.browse-input-nid {
  width: 200px;
  height: 200px;
  /*border-radius: 100%;*/
  transform: translate(-1px, -26px);
  opacity: 0;
}

</style>