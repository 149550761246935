import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import axios from "axios";

export const AgentEditMixin = {
    data() {
        return {
            first_name_state: null,
            last_name_state: null,
            form: {
                first_name: '',
                last_name: '',
                phone_number: '',
                pharmacy_details: {
                    secondary_phone_no: null,
                    organization_name: '',
                    pharmacist_name: '',
                    drag_license: '',
                    tin_no: '',
                },
                identity_card: '',
                identity_card_no: '',
                region_id: '',
                town_id: '',
                location: '',
                image: '',
            },
            districts: [],
            thana: [],
            exist_image: '',
            exist_image_nid: '',
            image_preview: null,
            image_preview_nid: null,
            id_card_image: '',
            district_required: '',
            thana_required: '',
        }
    },
    metaInfo: {
        title: 'Orko Health Ltd | Edit Agent',
        htmlAttrs: {
            lang: 'en-US'
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Edit agent profile", route: "/edit/agent/" + this.$route.params.id},
        ]);
    },
    created() {
        this.getAgent();
        this.getDistrict();
    },
    methods: {
        getAgent() {
            axios.get(`${process.env.VUE_APP_URL}/api/v1/user/profile/details/${this.$route.params.id}?api_token=${localStorage.getItem('api_token')}`)
                .then(response => {

                    this.agent_name = response.data.data.fullname
                    this.exist_image = response.data.data.image
                    this.exist_image_nid = response.data.data.identity_card
                    this.form = response.data.data;
                    this.id_card_image = response.data.data.identity_card;
                    this.form.pharmacy_details = response.data.data.pharmacy_details;
                    this.form.pharmacy_details.secondary_phone_no = response.data.data.pharmacy_details.secondary_phone_no;
                    // this.form.pharmacy_details.secondary_phone_no = response.data.data.pharmacy_details.secondary_phone_no;
                })
        },
        getDistrict() {
            axios.get(`${process.env.VUE_APP_URL}/api/v1/locations?location_type=city&parent_id=18`)
                .then(response => {
                    this.districts = response.data.location
                })
        },
        getThana(val) {
            axios.get(`${process.env.VUE_APP_URL}/api/v1/locations?location_type=town&parent_id=${val}`)
                .then(response => {
                    this.thana = response.data.location
                })
        },
        handleSubmit() {
            // Exit when the form isn't valid

            if (this.form.region_id == null) {
                return this.district_required = "District  required";
            } else if (this.form.town_id == null) {
                return this.thana_required = "Thana required";
            }

            let image = this.$refs.profile_image.files[0];

            let identity_card = this.$refs.identity_card.files ? this.$refs.identity_card.files[0] : '';
            // Push the name to submitted names
            let form = new FormData();
            form.append("phone_number", this.form.phone_number);

            form.append("secondary_phone_no", this.form.pharmacy_details.secondary_phone_no);
            form.append("drag_license", this.form.pharmacy_details.drag_license);
            form.append("tin_no", this.form.pharmacy_details.tin_no);
            form.append("pharmacist_name", this.form.pharmacy_details.pharmacist_name);
            form.append("identity_card_no", this.form.identity_card_no);
            form.append("identity_card", identity_card);
            form.append("country_id", 18);
            form.append("city_id", this.form.region_id);
            form.append("town_id", this.form.town_id);
            form.append("organization_name", this.form.pharmacy_details.organization_name);
            form.append("phone_number", this.form.phone_number);
            form.append("first_name", this.form.first_name);
            form.append("last_name", this.form.last_name);
            form.append("location", this.form.location);
            form.append("profile_img", image);

            // Hide the modal manually
            this.$nextTick(() => {
                this.loading = true
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v1/partner/agent/update/${this.$route.params.id}?_method=put&api_token=${localStorage.getItem('api_token')}`, form)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            this.loading = false
                            return this.$snotify.error(response.data.message);
                        }
                        this.loading = false
                        this.$snotify.success('Successfully saved');
                    })
            })
        },
        userPhoto(img) {
            if (img.match(/.(jpg|jpeg|png|gif)$/i)) {
                return `${process.env.VUE_APP_API_URL}/${img}`;
            } else {
                return "../../assets/img/blank.png";
            }
        },

        onFileChange(e) {
            const file = e.target.files[0];
            this.image_preview = URL.createObjectURL(file);
        },
        onFileChangeNid(e) {
            const nid_ifle = e.target.files[0];
            this.image_preview_nid = URL.createObjectURL(nid_ifle);
        }

    },
    watch: {
        'form.region_id': function (val) {
            this.getThana(val);
            if (this.form.region_id == null) {
                this.form.town_id = '';
            }
        },
        'form.town_id': function () {
            if (this.form.region_id == '') {
                this.form.town_id = '';
            }
        }
    },
}
